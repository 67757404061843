import React, {ReactElement, ReactNode, useContext,} from 'react';
import cn from 'classnames';
import {CardListItemMode} from "./CardListItemMode";
import './CardListItem.scss';

export interface CardListItemProps {
    id?: string;
    title: string;
    value?: string;
    mode?: CardListItemMode;
    children?: ReactNode;
    className?: string;
}

const CardListItemModeContext = React.createContext<CardListItemMode>(CardListItemMode.view);

export default function CardListItem({ id, title, value, mode = CardListItemMode.view, children, className }: CardListItemProps): ReactElement {
    return (
        <CardListItemModeContext.Provider value={mode}>
            <div id={id} className={cn('card-list-item', className)}>
                <CardListItem.View>
                    <strong className="card-list-item__title">{title}</strong>
                    {value && <p className="card-list-item__value">{value}</p>}
                </CardListItem.View>
                {children}
            </div>
        </CardListItemModeContext.Provider>
    );
}

interface CardListItemViewModeProps {
    children: ReactNode;
}

CardListItem.View = function View({ children }: CardListItemViewModeProps): ReactElement {
    const mode = useContext(CardListItemModeContext);
    return mode === CardListItemMode.view? <div>{children}</div> : <></>;
};
CardListItem.Edit = function Edit({ children }: CardListItemViewModeProps): ReactElement {
    const mode = useContext(CardListItemModeContext);
    return mode === CardListItemMode.edit? <div>{children}</div> : <></>;
};
