import React, { ReactElement } from "react";

const WarningIcon: React.FunctionComponent = (): ReactElement => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" aria-hidden>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#DB3624"
          d="M0 .9c0 .5.403.9.9.9a.898.898 0 00.9-.9.898.898 0 00-.9-.9.898.898 0 00-.9.9z"
          transform="translate(8.1 4.5)"
        />
        <path
          fill="#DB3624"
          d="M0 .903v4.494c0 .509.403.903.9.903a.9.9 0 00.9-.903V.903A.895.895 0 00.9 0a.9.9 0 00-.9.903z"
          transform="translate(8.1 7.2)"
        />
        <path
          fill="#DB3624"
          d="M9 16.2c-3.978 0-7.2-3.222-7.2-7.2S5.022 1.8 9 1.8s7.2 3.222 7.2 7.2-3.222 7.2-7.2 7.2M9 0C4.032 0 0 4.032 0 9s4.032 9 9 9 9-4.032 9-9-4.032-9-9-9"
        />
      </g>
    </svg>
  );
};

export default WarningIcon;
