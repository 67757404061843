import React, { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import { Redirect as ReactRouterRedirect } from 'react-router';

interface RedirectProps {
  readonly to: string;
}

export function Redirect({ to }: RedirectProps): ReactElement {
  const query = useLocation().search;
  return <ReactRouterRedirect to={to + query} />;
}
