import React, { ReactElement, useEffect } from 'react';
import { verifyEmailVerificationToken } from './verifyTokenGateway';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { NEW_TO_SCHEME_EXPIRED, NEW_TO_SCHEME_PASSWORD } from '../routes';
import { PageLoading } from '../../../components/pageLoading/PageLoading';
import { VerificationParams } from '../CreateAccountFlow';

interface VerifyPageProps {
  readonly setVerifiedToken: (verifiedToken: string) => void;
}

export function VerifyPage({ setVerifiedToken }: VerifyPageProps): ReactElement {
  const history = useHistory();
  const { emailToken } = useParams<VerificationParams>();

  useEffect(() => {
    const fetch = async (): Promise<void> => {
      try {
        const verifiedToken = await verifyEmailVerificationToken(emailToken);
        setVerifiedToken(verifiedToken);
        history.push(NEW_TO_SCHEME_PASSWORD.replace(':emailToken', emailToken));
      } catch (e) {
        history.push(NEW_TO_SCHEME_EXPIRED);
      }
    };

    void fetch();
  }, [emailToken, setVerifiedToken, history]);

  return <PageLoading />;
}
