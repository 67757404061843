import React, { ReactElement } from 'react';
import styles from './errorCode.module.scss';
import { isEmpty } from 'lodash';

interface ErrorCodeProps {
  readonly errorCode: string;
}

export function ErrorCode({ errorCode }: ErrorCodeProps): ReactElement {
  if (isEmpty(errorCode)) return <></>;
  return <div className={styles.errorCode}>{`Error code - ${errorCode}`}</div>;
}
