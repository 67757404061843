import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

type AnalyticsData = { [key: string]: string | null };

interface AnalyticsEvent extends AnalyticsData {
  readonly event: string;
  readonly eventCategory: string;
  readonly eventAction: string | null;
}

function push(data: AnalyticsData): void {
  // @ts-ignore
  window.dataLayer = window.dataLayer || [];

  // @ts-ignore
  window.dataLayer.push(data);
}

function pushEvent(event: AnalyticsEvent): void {
  push(event);
}

export function analyticsFailedFieldValidation(fieldName: string | undefined, errorText: string): void {
  pushEvent({
    event: 'failedFieldvalidation',
    eventCategory: 'Failed Validation',
    eventAction: fieldName || null,
    eventLabel: errorText,
  });
}

export const createGenericEvent = (name: string, category: string, action: string, label: string | null): void => {
  const obj: AnalyticsEvent = {
    event: name,
    eventCategory: category,
    eventAction: action,
    eventLabel: label,
  };

  pushEvent(obj);
};

export function useAnalyticsPageView(): void {
  const location = useLocation();

  useEffect(() => {
    push({
      event: 'virtualPageview',
      pageStep: location.pathname,
    });
  }, [location]);
}
