import { PingPasswordViolationError } from '../../../common/errors';
import { FormError, PingValidations } from '../../../common/formValidation';

export function transformPingPasswordErrorsToFormError(
  pingPasswordViolationError: PingPasswordViolationError,
): ReadonlyArray<FormError> {
  const pingPasswordViolations: PingValidations = [
    {
      message: 'Please enter a password that is different to your email username',
      validate: pingPasswordViolationError.passwordIsUsername,
    },
    {
      message: 'Please enter a password that has less than 65 characters',
      validate: pingPasswordViolationError.over64Characters,
    },
    {
      message: 'The password you entered is not secure enough. Please enter a different one',
      validate: pingPasswordViolationError.foundInCommonlyUsedList,
    },
    {
      message: 'Please enter a password with valid characters',
      validate: pingPasswordViolationError.containsInvalidCharacters,
    },
  ];
  return pingPasswordViolations
    .filter((error) => error.validate)
    .map((item) => {
      return {
        label: 'Password',
        message: item.message,
      };
    });
}
