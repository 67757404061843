import { useRef } from 'react';
import { nanoid } from 'nanoid';

const generateUniqueId = (prefix: string): string => {
    return prefix + nanoid();
}

const useUniqueId = (prefix: string) => {
    const id = useRef(generateUniqueId(prefix));
    return id.current;
};

export default useUniqueId;
