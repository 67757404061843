import axios from 'axios';
import { SIGN_UP_BASE_PATH } from './routes';

axios.defaults.timeout = 6000;
axios.defaults.headers['Expires'] = '-1';
axios.defaults.headers['Cache-Control'] = 'no-cache,no-store,must-revalidate,max-age=-1,private';
axios.defaults.xsrfCookieName = 'XSRF-TOKEN';

export function apiUrl(endpoint: string, version = 'v1'): string {
  return `${SIGN_UP_BASE_PATH}/api/${version}/${endpoint}`;
}
