import React, { ReactElement } from 'react';
import sanitizeHtml from 'sanitize-html';
import classnames from 'classnames';

export default function IconAlert(alertText: string, alertClassName?: string): ReactElement {
    return (
        <div
            data-locator={'alert-div'}
            className={classnames('simple-form__simple-input-alert-message', alertClassName)}
        >
            <div>
                <svg
                    data-locator={'icon'}
                    aria-hidden={true}
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                >
                    <circle cx='12' cy='12' r='9' fill='#B82D1D' stroke='#B82D1D' strokeWidth='2' />
                    <rect
                        x='13.4062'
                        y='13.4775'
                        width='2.8125'
                        height='8.17383'
                        rx='1.40625'
                        transform='rotate(-180 13.4062 13.4775)'
                        fill='white'
                    />
                    <rect
                        x='13.4062'
                        y='17.6963'
                        width='2.8125'
                        height='2.8125'
                        rx='1.40625'
                        transform='rotate(-180 13.4062 17.6963)'
                        fill='white'
                    />
                </svg>
            </div>
            <div data-locator={'alert-message'} dangerouslySetInnerHTML={{ __html: sanitizeHtml(alertText) }} />
        </div>
    );
}
