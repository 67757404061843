import React, {ButtonHTMLAttributes, DetailedHTMLProps, ReactElement} from 'react';
import cn from 'classnames';
import './loadable-button.scss';
import SpinnerCircle from './SpinnerCircle';

export type LoadingButtonProps =
    Omit<DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, 'onClick' | 'disabled'>
    & {
    disabled?: boolean;
    loading: boolean;
    loadingText: string;
    notLoadingText?: string;
    className?: string;
    theme?: LoadableButtonThemes;
    classNameNotLoading?: string;
    classNameLoading?: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    ariaLabel?: string;
}


export enum LoadableButtonThemes {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
}

export default function LoadableButton(
    {
        loading, disabled, loadingText, theme, notLoadingText, onClick, className, classNameNotLoading = '',
        classNameLoading = '', ariaLabel, ...otherProps
    }: LoadingButtonProps): ReactElement {
    const buttonText = loading ? loadingText : notLoadingText;
    const aria = ariaLabel ? ariaLabel : buttonText;
    const buttonTheme = theme ? theme === 'primary' ? 'primary' : 'secondary' : 'primary'



    const loadableButtonStyles = cn(
        'loadable-button__button--' + buttonTheme,
        className,
        {
            [classNameNotLoading]: classNameNotLoading && !loading,
            [classNameLoading]: classNameLoading && loading,
            'loadable-button__button--loading': !classNameLoading && loading,
        },
    );

    return (
        <button
            className={loadableButtonStyles}
            onClick={onClick}
            disabled={disabled || loading}
            aria-label={aria}
            aria-live={loading ? 'assertive': 'off'}
            {...otherProps}
        >

            {loading && <SpinnerCircle theme={buttonTheme}/>}
            {buttonText}
        </button>
    );
}
