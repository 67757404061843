import React, { ReactElement, ReactNode } from 'react';
import styles from './page.module.scss';
import Title from '../title/Title';
import { PageErrorBox } from '@mo/page-error-box';
import { FormError, hasValidationErrors } from '../../common/formValidation';
import { isEmpty } from 'lodash';
import { PadlockIcon } from '../padlockIcon/PadlockIcon';
import { BackButton } from '../backButton/BackButton';

export interface PageProps {
  readonly title: string;
  readonly validationErrors?: ReadonlyArray<FormError>;
  readonly children: ReactNode;
  readonly beforeForm?: ReactElement;
  readonly backTo?: string;
  readonly withSecurityPromise?: boolean;
}

export function Page({
  title,
  validationErrors,
  backTo,
  withSecurityPromise = true,
  beforeForm,
  children,
}: PageProps): ReactElement {
  return (
    <div className={styles.page}>
      {backTo && <BackButton to={backTo} className={styles.back} />}
      {validationErrors && (
        <PageErrorBox
          hasErrors={hasValidationErrors(validationErrors)}
          errors={validationErrors.filter((e) => !isEmpty(e.message))}
        />
      )}
      <Title title={title} className={!withSecurityPromise || beforeForm ? styles.fullWidthTitle : styles.title} />
      {beforeForm}
      <div className={styles.contents}>
        <div className={styles.form}>{children}</div>
        {withSecurityPromise && (
          <div className={styles.securityPromise}>
            <PadlockIcon />
          </div>
        )}
      </div>
    </div>
  );
}
