import { isEmpty } from 'lodash';

export type FormValidations = ReadonlyArray<{
  readonly message: string;
  readonly validate: (value: string) => boolean;
}>;

export type PingValidations = ReadonlyArray<{
  readonly message: string;
  readonly validate: boolean;
}>;

export interface FormError {
  readonly label: string;
  readonly message: string;
}

export function firstValidationError(value: string, validations: FormValidations): string {
  const failedValidation = validations.find((validation) => validation.validate(value));
  return failedValidation?.message ?? '';
}

export function validationErrorsFor(label: string, errors: ReadonlyArray<FormError>): ReadonlyArray<string> {
  return errors
    .filter((error) => error.label === label)
    .map((error) => error.message)
    .filter((error) => !isEmpty(error));
}

export function hasValidationErrors(errors: ReadonlyArray<FormError>): boolean {
  return errors.some((e) => !isEmpty(e.message));
}

export function clearValidationErrorsFor(
  label: string,
): (errors: ReadonlyArray<FormError>) => ReadonlyArray<FormError> {
  return (errors): ReadonlyArray<FormError> => errors.filter((e) => e.label !== label);
}
