import React, { FormEvent, useState } from 'react';
import { isEmpty } from 'lodash';
import { SimpleInput } from '@mo/simple-form';
import styles from './emailPage.module.scss';
import { LoadableButton } from '@mo/loadable-button';
import {
  clearValidationErrorsFor,
  firstValidationError,
  FormError,
  FormValidations,
  validationErrorsFor,
} from '../../../common/formValidation';
import { emailValidation } from '@mo/validation';
import { submitEmail, SubmitEmailRequest } from './submitEmailGateway';
import { TechnicalError } from '../../../components/technicalError/TechnicalError';
import { useHistory } from 'react-router-dom';
import { NEW_TO_SCHEME_EMAIL_SENT, NEW_TO_SCHEME_NAME } from '../routes';
import { Page } from '../../../components/page/Page';
import { Names } from '../CollectDetailsFlow';
import { SignupSource } from '../SetSignupSource';

const emailValidations: FormValidations = [
  { message: 'Please enter an email address', validate: emailValidation.emailIsMissing },
  { message: 'Please enter a valid email address', validate: emailValidation.emailFailsSimpleFormatChecks },
  { message: 'Please enter an email address that has less than 51 characters', validate: emailValidation.emailTooLong },
];

interface EnterEmailProps {
  readonly signupSource?: SignupSource;
  readonly names: Names;
  readonly emailAddress: string;
  readonly setEmailAddress: (email: string) => void;
}

export function EmailPage({ names, emailAddress, setEmailAddress, signupSource }: EnterEmailProps): React.ReactElement {
  const history = useHistory();
  const [submitting, setSubmitting] = useState(false);
  const [validationErrors, setValidationErrors] = useState<ReadonlyArray<FormError>>([]);
  const [technicalError, setTechnicalError] = useState(false);
  const [errorCode, setErrorCode] = useState<string>('');

  const onSubmit = async (formEvent: FormEvent): Promise<void> => {
    formEvent.preventDefault();

    const validationError = firstValidationError(emailAddress, emailValidations);

    if (isEmpty(validationError)) {
      setSubmitting(true);
      try {
        const submitEmailRequest: SubmitEmailRequest = {
          firstName: names.firstName,
          lastName: names.lastName,
          email: emailAddress,
          signupSource: signupSource,
        };

        await submitEmail(submitEmailRequest);
        history.push(NEW_TO_SCHEME_EMAIL_SENT);
      } catch (error) {
        setErrorCode('SE1');

        setTechnicalError(true);
        setSubmitting(false);
      }
    } else {
      setValidationErrors([{ label: 'Email address', message: validationError }]);
    }
  };
  return (
    <Page title='Enter email address' validationErrors={validationErrors} backTo={NEW_TO_SCHEME_NAME}>
      <p>
        Please enter an email address below. This is how you will sign in to your Motability Scheme online account and
        where we will send Scheme email correspondence.
      </p>
      <p>We will send you a verification email to confirm.</p>
      <form noValidate className={styles.emailAddressForm} onSubmit={onSubmit}>
        <SimpleInput
          labelText='Email address'
          inputName='emailAddress'
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
            setEmailAddress(e.target.value.trim());
            setValidationErrors(clearValidationErrorsFor('Email address'));
          }}
          value={emailAddress}
          errors={validationErrorsFor('Email address', validationErrors)}
          autoComplete='email'
          type='email'
          data-cs-mask='data-cs-mask'
        />
        <TechnicalError visible={technicalError} errorCode={errorCode}>
          Sorry, we're having some problems sending you a verification email. Please try again in a few moments. If this
          problem persists please call us on <a href={'tel:0300 456 4566'}>0300 456 4566</a> and quote the error code to
          the advisor.
        </TechnicalError>
        <LoadableButton
          loading={submitting}
          loadingText='Sending verification email'
          notLoadingText='Send verification email'
          className={styles.saveButton}
          data-ga='save-button'
        />
      </form>
    </Page>
  );
}
