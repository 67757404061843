import React from 'react';
import PropTypes from 'prop-types';
import './visually-hidden.scss';

function VisuallyHidden({children}) {
  return (
    <div className='accessibility__visually-hidden' data-locator='visually-hidden'>
      {children}
    </div>
  );
}

VisuallyHidden.propTypes = {
  children: PropTypes.node
};

export default VisuallyHidden;
