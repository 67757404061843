import React, { ReactElement } from 'react';
import classnames from 'classnames';
import _uniqueId from 'lodash.uniqueid';
import isEmpty from 'lodash.isempty';
import './simple-form.scss';
import IconAlert from './icons/IconAlert';

export interface SimpleInputProps extends React.HTMLProps<HTMLInputElement> {
    inputName: string;
    labelText?: string;
    ariaLabelText?: string;
    hintText?: string;
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    groupClassName?: string;
    inputFieldClassName?: string;
    inputContainerClassName?: string;
    labelClassName?: string;
    hintClassName?: string;
    maxLength?: number;
    errors?: string[];
    onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    highlightInlineError?: boolean;
    type?: string;
    optional?: boolean;
    alertClassName?: string;
    alertText?: string;
}

function SimpleInput({
    inputName,
    labelText,
    ariaLabelText,
    hintText,
    value,
    onChange,
    groupClassName,
    inputFieldClassName,
    inputContainerClassName,
    labelClassName,
    hintClassName,
    maxLength,
    errors,
    onKeyPress,
    highlightInlineError,
    type,
    optional,
    alertClassName,
    alertText,
    ...inputProps
}: SimpleInputProps): ReactElement {
    const uniqueId = _uniqueId(`${inputName}-`);
    const uniqueHintId = hintText && _uniqueId(`${inputName}Hint-`);

    const formInputClasses = classnames(
        { ['simple-form__error-highlight']: !isEmpty(errors) || highlightInlineError },
        'simple-form__simple-input',
        groupClassName
    );

    // @ts-ignore
    return (
        <div className={formInputClasses}>
            {labelText && (
                <label className={classnames('simple-form__simple-input-label', labelClassName)} htmlFor={uniqueId}>
                    {labelText}
                    {optional && <span className='simple-form__simple-input-label-optional'> (optional)</span>}
                </label>
            )}
            {hintText && (
                <span className={classnames('simple-form__simple-input-hint', hintClassName)} id={uniqueHintId}>
                    {hintText}
                </span>
            )}
            {errors && !isEmpty(errors) && (
                <ul className={'simple-form__inline-errors'} data-locator='simple-input-errors'>
                    {errors.map((error) => (
                        <li key={error} data-locator='error-message' className={'simple-form__inline-error-message'}>
                            {error}
                        </li>
                    ))}
                </ul>
            )}
            {alertText && IconAlert(alertText, alertClassName)}
            <div className={inputContainerClassName}>
                <input
                    className={classnames(
                        { ['simple-form__error']: !isEmpty(errors) },
                        'simple-form__input-field',
                        inputFieldClassName
                    )}
                    id={uniqueId}
                    aria-describedby={uniqueHintId}
                    aria-label={ariaLabelText}
                    type={type || 'text'}
                    name={inputName}
                    value={value}
                    maxLength={maxLength}
                    onChange={(event): void => {
                        event.preventDefault();
                        onChange(event);
                    }}
                    onKeyPress={onKeyPress}
                    {...inputProps}
                />
            </div>
        </div>
    );
}

export default SimpleInput;
