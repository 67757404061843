import React, {ReactNode} from 'react';
import { IconInfo } from '@mo/icons';
import './infoNotificationBox.scss';
import cn from "classnames";

interface Props {
    children: ReactNode;
    variant?: 'error' | 'warn' | 'info' | 'success' | 'white-info' | 'no-border';
    icon?: ReactNode;
    className?: string;
}

interface InfoNotificationClass {
    infoNotificationClass: string;
    iconClass: string;
}

function calculateInfoNotificationClass(variant: 'error' | 'warn' | 'info' | 'success' | 'white-info' | 'no-border'): InfoNotificationClass {
    switch (variant) {
        case 'warn':
            return { infoNotificationClass: 'warnNotificationBox', iconClass: '#000000' };
        case 'error':
            return  { infoNotificationClass: 'errorNotificationBox', iconClass: '#000000' };
        case 'success':
            return  { infoNotificationClass: 'successNotificationBox', iconClass: '#000000' };
        case 'white-info':
            return  { infoNotificationClass: 'whiteInfoNotificationBox', iconClass: '#000000' };
        case 'no-border':
            return  { infoNotificationClass: 'noBorderBox', iconClass: '#000000' };
        case 'info':
        default:
            return  { infoNotificationClass: 'infoNotificationBox', iconClass: '#000000' };
    }
}

export function InfoNotificationBox(props: Props) {
    const variant = props.variant || 'info';

    const infoNotificationClass = calculateInfoNotificationClass(variant);

    return (
        <div tabIndex={0} className={cn(infoNotificationClass.infoNotificationClass, props.className)} >
            <div className='infoNotificationBoxIcon'>
                {props.icon || <IconInfo color={infoNotificationClass.iconClass} aria-hidden='true' />}
            </div>
            <div className='infoNotificationBoxText'>
                {props.children}
            </div>
        </div>
    );
}
