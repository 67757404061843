import { apiUrl } from '../../../common/gateway';
import { isEmpty } from 'lodash';
import axios from 'axios';

interface VerifyEmailVerificationTokenResponse {
  emailVerificationToken: string;
}

export async function verifyEmailVerificationToken(token: string): Promise<string> {
  if (isEmpty(token)) {
    throw new Error('token can not be empty');
  }
  const response: VerifyEmailVerificationTokenResponse = (await axios.get(apiUrl(`verify-email-token/${token}`, 'v1')))
    .data;
  return response.emailVerificationToken;
}
