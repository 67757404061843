import React, { ReactElement, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router';
import {
  NEW_TO_SCHEME_BASE_PATH,
  NEW_TO_SCHEME_EMAIL,
  NEW_TO_SCHEME_EMAIL_SENT,
  NEW_TO_SCHEME_NAME,
  NEW_TO_SCHEME_PRECHECK,
} from './routes';
import { EmailPage } from './email/EmailPage';
import { NamePage } from './name/NamePage';
import { EmailSentPage } from './emailSent/EmailSentPage';
import { Redirect } from '../../components/redirect/Redirect';
import { SetSignupSource, SignupSource } from './SetSignupSource';
import { PrecheckPage } from './precheck/PrecheckPage';
import { useCookies } from 'react-cookie';
import { v4 } from 'uuid';

export interface Names {
  readonly firstName: string;
  readonly lastName: string;
}

export function CollectDetailsFlow(): ReactElement {
  const [cookies, setCookie] = useCookies(['n2s']);

  useEffect(() => {
    !cookies.n2s &&
      setCookie('n2s', v4(), {
        maxAge: 7200,
        path: '/',
      });
  }, [setCookie, cookies.n2s]);

  const [names, setNames] = useState<Names | null>(null);
  const [emailAddress, setEmailAddress] = useState('');
  const [precheckConfirmed, setPrecheckConfirmed] = useState(false);
  const [signupSource, setSignupSource] = useState<SignupSource | undefined>();

  return (
    <>
      <Switch>
        <Route exact path={NEW_TO_SCHEME_PRECHECK}>
          <SetSignupSource setSignupSource={setSignupSource}>
            <PrecheckPage onSuccessfulSubmit={setPrecheckConfirmed} />
          </SetSignupSource>
        </Route>
        <Route exact path={NEW_TO_SCHEME_NAME}>
          {precheckConfirmed ? (
            <NamePage initialNames={names} onSuccessfulSubmit={setNames} />
          ) : (
            <Redirect to={NEW_TO_SCHEME_BASE_PATH} />
          )}
        </Route>
        <Route exact path={NEW_TO_SCHEME_EMAIL}>
          {precheckConfirmed && names ? (
            <EmailPage
              signupSource={signupSource}
              emailAddress={emailAddress}
              setEmailAddress={setEmailAddress}
              names={names}
            />
          ) : (
            <Redirect to={NEW_TO_SCHEME_BASE_PATH} />
          )}
        </Route>
        <Route exact path={NEW_TO_SCHEME_EMAIL_SENT}>
          {precheckConfirmed && names && emailAddress ? (
            <EmailSentPage email={emailAddress} />
          ) : (
            <Redirect to={NEW_TO_SCHEME_BASE_PATH} />
          )}
        </Route>
      </Switch>
    </>
  );
}
