import { ReactElement, useEffect } from 'react';
import * as React from 'react';
import { ForceFocus } from '@mo/accessibility';

export type TitleProps = {
  title: string;
  className?: string;
  dataLocator?: string;
};

export function Title(props: TitleProps): ReactElement {
  useEffect((): void => {
    document.title = `${props.title} - Motability`;
  }, [props]);

  return (
    <ForceFocus focus={true}>
      <h1 data-locator={props.dataLocator} className={props.className}>
        {props.title}
      </h1>
    </ForceFocus>
  );
}

export default Title;
