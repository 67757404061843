import React, {ReactElement, KeyboardEvent, Fragment} from "react";
import './linkable-error-message.scss';
import {LinkableErrorMessageProps} from "./PageErrorBox";

const LinkableErrorMessage = (props: LinkableErrorMessageProps): ReactElement => {
  const focus = (element: HTMLElement) => {
    if (props.selectorInnerFocus) {
      const focusElement: HTMLElement | null = element.querySelector(props.selectorInnerFocus);
      focusElement && focusElement.focus({ preventScroll: true });
    } else {
      element.focus()
    }
  };

  // Hash links don't work with React router nicely so just manually scroll to the element if found
  // https://github.com/ReactTraining/react-router/issues/394
  const scrollToError = (): void => {
    let element: HTMLElement | null = null;
    if (props.selector) {
      element = document.querySelector(props.selector);
    } else {
      const labelElements = document.querySelectorAll('label');
      for (let i = 0; i < labelElements.length; i++) {
        if (labelElements[i].textContent === props.label) {
          const elementId = labelElements[i].getAttribute('for');
          if (elementId) {
            element = document.getElementById(elementId);
          }
        }
      }
    }
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      focus(element);
    }
  };

  const scrollForSpaceAndEnter = (event: KeyboardEvent): void => {
    event.preventDefault();
    if (event.key === 'Enter' || event.key === ' ') {
      scrollToError();
    }
  };

  const label = (
    <Fragment>
      <span
        role='link'
        tabIndex={0}
        className={'page-error-box__linkable-error__a'}
        onClick={(): void => scrollToError()}
        onKeyUp={scrollForSpaceAndEnter}
        data-locator='error-anchor'
      >
        {props.label}
      </span>
      <span>: </span>
    </Fragment>
  );

  return (
    <div className={'page-error-box__linkable-error__message'} data-ga={props.analyticsTag} data-locator='error-header-hint'>
      {props.label && label}
      <span data-locator='error-text'>{props.message}</span>
    </div>
  );
};

export default LinkableErrorMessage;
