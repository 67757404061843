import 'react-app-polyfill/stable';
import React, { ReactElement } from 'react';
import { createRoot } from 'react-dom/client';
import { UnhandledError } from '@mo/unhandled-error';
import * as serviceWorker from './common/serviceWorker';
import 'normalize.css';
import './reset.local.scss';
import './index.scss';
import '@mo/gearbox/classic-theme.css';
import './common/gearboxComponents';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createBrowserHistory } from 'history';
import AppContainer from './AppContainer';
import { Router } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';

const history = createBrowserHistory();

const Index: React.FunctionComponent = (): ReactElement => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        staleTime: 0,
        gcTime: 0,
        refetchOnWindowFocus: false,
      },
    },
  });
  return (
    <UnhandledError>
      <Router history={history}>
        <QueryClientProvider client={queryClient}>
          <CookiesProvider>
            <AppContainer />
          </CookiesProvider>
        </QueryClientProvider>
      </Router>
    </UnhandledError>
  );
};

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(<Index />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
