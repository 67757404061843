import React, { ReactElement, ReactNode } from 'react';
import { ForceFocus } from '@mo/accessibility';
import cn from 'classnames';
import './page-error-box.scss';
import WarningIcon from "./icon/WarningIcon";
import LinkableErrorMessage  from './LinkableErrorMessage';

export interface PageErrorBoxProps {
  hasErrors: boolean;
  errors?: LinkableErrorMessageProps[];
  analyticsTag?: string;
  message?: string;
  className?: string;
  focus?: () => void;
  removeFocus?: () => void;
  icon?: ReactNode;
  errorBoxTitle?: string;
}

export type LinkableErrorMessageProps = {
  message: ReactNode;
  label?: string;
  selector?: string;
  analyticsTag?: string;
  selectorInnerFocus?: string;
};

const PageErrorBox: React.FunctionComponent<PageErrorBoxProps> = (props): ReactElement | null => {
  const uniqueAnalyticsTag = (error: LinkableErrorMessageProps): string => `${error.label}-${error.message}`.toLowerCase();

  if (props.hasErrors) {
    return (
      <ForceFocus removeFocus={props.removeFocus} focus={props.focus}>
        <div className={cn('page-error-box__error-box', props.className)} data-locator='error-box'>
          <div className={'page-error-box__heading'}>
            {props.icon ? props.icon : <WarningIcon />}
            <span>{props.errorBoxTitle ? props.errorBoxTitle : "Please review the following to continue"}:</span>
          </div>
          <ul className={'page-error-box__list'} data-locator='error-list'>
            {
              props.children && React.Children.map(props.children, (child) => <li>{child}</li>)
            }
            {
              props.errors && props.errors.map(
                (error: LinkableErrorMessageProps, index): ReactElement =>
                  <li key={index}>
                    <LinkableErrorMessage
                      message={error.message}
                      label={error.label}
                      analyticsTag={props.analyticsTag ? props.analyticsTag : uniqueAnalyticsTag(error)}
                      selector={error.selector}
                      selectorInnerFocus={error.selectorInnerFocus}
                    />
                  </li>
              )
            }
          </ul>
          {
            props.message &&
            <p data-locator='error-box-message' className='page-error-box__error-box__message'>
              {props.message}
            </p>
          }
        </div>
      </ForceFocus>
    );
  } else {
    return null;
  }
};

export default PageErrorBox
