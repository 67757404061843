import styles from './backButton.module.scss';
import { ReactComponent as LeftArrowIcon } from '../../assets/left-arrow.svg';
import React, { ReactElement, ReactNode } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

interface BackButtonProps {
  to: string;
  className?: string;
  children?: ReactNode;
}

export function BackButton({ to, className, children = 'Back' }: BackButtonProps): ReactElement {
  return (
    <Link className={cn(styles.backToAccountButton, className)} to={to}>
      <LeftArrowIcon className={styles.icon} />
      <div className={styles.text}>{children}</div>
    </Link>
  );
}
