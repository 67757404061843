import React, { ReactElement } from 'react';
import styles from './validation.module.scss';
import { validations } from './validation';

interface ValidationListProps {
  password?: string;
  hasErrors: boolean;
}

const ValidationList: React.FunctionComponent<ValidationListProps> = (props): ReactElement => {
  return (
    <ul id={'password-validation-list'} className={styles.rules}>
      {validations.map(
        (validation, index): ReactElement => (
          <li
            key={index + Date.now()}
            className={
              props.password
                ? validation.validate(props.password)
                  ? styles.validationGood
                  : styles.validationBad
                : undefined
            }
            data-ga={
              props.hasErrors && props.password && !validation.validate(props.password)
                ? validation.analyticsTag
                : undefined
            }
            aria-label={
              props.password
                ? validation.validate(props.password)
                  ? `${validation.label} - requirement met`
                  : `${validation.label} - requirement not met`
                : undefined
            }
          >
            {validation.label}
          </li>
        ),
      )}
    </ul>
  );
};

export default ValidationList;
