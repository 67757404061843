import React, { ReactElement } from 'react';
import classnames from 'classnames';
import _uniqueId from 'lodash.uniqueid';
import isEmpty from 'lodash.isempty';
import './simple-form.scss';

export interface SimpleTextAreaProps extends React.HTMLProps<HTMLTextAreaElement> {
    inputName: string;
    labelText?: string;
    ariaLabelText?: string;
    hintText?: string;
    value: string;
    onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    groupClassName?: string;
    inputFieldClassName?: string;
    inputContainerClassName?: string;
    labelClassName?: string;
    hintClassName?: string;
    maxLength?: number;
    errors?: string[];
    onKeyPress?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
    highlightInlineError?: boolean;
    fixedSize?: boolean;
    optional?: boolean;
    errorTextClassName?: string;
}

function SimpleTextArea({
    inputName,
    labelText,
    ariaLabelText,
    hintText,
    value,
    onChange,
    groupClassName,
    inputFieldClassName,
    inputContainerClassName,
    labelClassName,
    hintClassName,
    maxLength,
    errors,
    onKeyPress,
    highlightInlineError,
    fixedSize,
    optional,
    errorTextClassName,
    ...inputProps
}: SimpleTextAreaProps): ReactElement {
    const uniqueId = _uniqueId(`${inputName}-`);
    const uniqueHintId = hintText && _uniqueId(`${inputName}Hint-`);

    const formInputClasses = classnames(
        { ['simple-form__error-highlight']: !isEmpty(errors) || highlightInlineError },
        'simple-form__simple-input',
        groupClassName
    );

    // @ts-ignore
    return (
        <div className={formInputClasses}>
            {labelText && (
                <label className={classnames('simple-form__simple-input-label', labelClassName)} htmlFor={uniqueId}>
                    {labelText}
                    {optional && <span className='simple-form__simple-input-label-optional'> (optional)</span>}
                </label>
            )}
            {hintText && (
                <span className={classnames('simple-form__simple-input-hint', hintClassName)} id={uniqueHintId}>
                    {hintText}
                </span>
            )}
            {errors && !isEmpty(errors) && (
                <ul className={'simple-form__inline-errors'} data-locator='simple-input-errors'>
                    {errors.map((error) => (
                        <li
                            key={error}
                            data-locator='error-message'
                            className={classnames('simple-form__inline-error-message', errorTextClassName)}
                        >
                            {error}
                        </li>
                    ))}
                </ul>
            )}
            <div className={inputContainerClassName}>
                <textarea
                    className={classnames(
                        { ['simple-form__error']: !isEmpty(errors) },
                        'simple-form__textarea',
                        { ['simple-form__not-resizable']: fixedSize },
                        inputFieldClassName
                    )}
                    id={uniqueId}
                    aria-describedby={uniqueHintId}
                    aria-label={ariaLabelText}
                    name={inputName}
                    value={value}
                    maxLength={maxLength}
                    onChange={(event): void => {
                        event.preventDefault();
                        onChange(event);
                    }}
                    onKeyPress={onKeyPress}
                    {...inputProps}
                />
            </div>
        </div>
    );
}

export default SimpleTextArea;
