import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

export const TechnicalDetails = ({message, hidden}) => {
  if (hidden) return null;

  const details = [
    message,
    '',
    `Platform: ${navigator.platform}`,
    `UserAgent: ${navigator.userAgent}`
  ].join('\n');

  return (
    <Fragment>
      <h2>Technical details</h2>
      <pre>{details}</pre>
    </Fragment>
  );
};

TechnicalDetails.propTypes = {
  message: PropTypes.string.isRequired,
  hidden: PropTypes.bool
};
