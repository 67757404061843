import React, {ReactElement, ReactNode} from 'react';
import cn from 'classnames';
import './CardHeader.scss';

interface CardHeaderProps {
    children: ReactNode;
    image?: string;
    imageAlt?: string;
    className?: string;
}

export default function CardHeader({children, image, imageAlt, className}: CardHeaderProps): ReactElement {
    return (
        <header className={cn('card-header', className)}>
            {image &&
            <div className='card-header__icon' aria-hidden={true}>
                <img className='card-header__image' src={image} alt={imageAlt}/>
            </div>}
            <h2 className='card-header__title'>{children}</h2>
        </header>
    );
}
