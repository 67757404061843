import { ReactComponent as WarningIcon } from '../../assets/warning.svg';
import { ServiceErrorMessage } from '@mo/service-error-message';
import React, { ReactElement, ReactNode } from 'react';
import styles from './technicalError.module.scss';
import { ErrorCode } from '../errorCode/ErrorCode';

interface TechnicalErrorProps {
  visible: boolean;
  errorCode: string;
  children: ReactNode;
}

export function TechnicalError({ visible, errorCode, children }: TechnicalErrorProps): ReactElement {
  return (
    <ServiceErrorMessage
      className={styles.technicalError}
      visible={visible}
      title='A technical error happened'
      hintClassName={styles.technicalErrorHint}
      titleClassName={styles.technicalErrorTitle}
      messageClassName={styles.technicalErrorMessage}
      icon={<WarningIcon />}
      errorCode={<ErrorCode errorCode={errorCode} />}
    >
      {children}
    </ServiceErrorMessage>
  );
}
