import React, { ReactElement } from "react";
import classnames from 'classnames';
import './external-link.scss';
import { IconLink } from '@mo/icons';
import useUniqueId from "./useUniqueId";

type AnchorProps = React.HTMLProps<HTMLAnchorElement>;

export interface ExternalLinkProps extends AnchorProps {
    readonly analyticsTag?: string;
    readonly withoutIcon?: boolean;
}

const linkBlue = '#3357b8';

export function ExternalLink({
    className,
    analyticsTag,
    children,
    withoutIcon = false,
    ...rest
}: ExternalLinkProps): ReactElement {
    const classes = classnames('ExternalLink', className);
    const uniqueScreenReaderTextId = useUniqueId('screen-reader-text-');

    return (
        <>
            <a className={classes} target='_blank' rel='noopener noreferrer' aria-describedby={uniqueScreenReaderTextId} data-ga={analyticsTag} {...rest}>
                {children}
                {withoutIcon ? <></> : <IconLink className='ExternalLink__icon' color={linkBlue} aria-hidden={true} />}
            </a>
            <span id={uniqueScreenReaderTextId} hidden>Opens in a new tab or window</span>
        </>
    );
}

export default ExternalLink;
