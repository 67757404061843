import React, { ReactElement } from 'react';
import { Route, Switch } from 'react-router-dom';
import { SUCCESS_PAGE } from './common/routes';
import { ActivationCompletePage } from './pages/activationComplete/ActivationCompletePage';
import { NewToSchemeFlow } from './flows/newToScheme/NewToSchemeFlow';
import { NEW_TO_SCHEME_BASE_PATH } from './flows/newToScheme/routes';
import { Redirect } from './components/redirect/Redirect';

export function Routes(): ReactElement {
  return (
    <Switch>
      <Route exact path={SUCCESS_PAGE} component={ActivationCompletePage} />

      <Route path={NEW_TO_SCHEME_BASE_PATH} component={NewToSchemeFlow} />

      <Redirect to={NEW_TO_SCHEME_BASE_PATH} />
    </Switch>
  );
}
