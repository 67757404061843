import { apiUrl } from '../../../common/gateway';
import { SignupSource } from '../SetSignupSource';
import axios from 'axios';

export interface SubmitEmailRequest {
  email: string;
  firstName: string;
  lastName: string;
  signupSource?: SignupSource;
}

export async function submitEmail(submitEmailRequest: SubmitEmailRequest): Promise<void> {
  await axios.post(apiUrl('submit-email', 'v2'), { ...submitEmailRequest });
}
