import React, { ChangeEvent, FormEvent, ReactElement, useState } from 'react';
import { SimpleInput } from '@mo/simple-form';
import { LoadableButton } from '@mo/loadable-button';
import { InfoNotificationBox } from '@mo/info-box';
import { useHistory } from 'react-router-dom';
import { NEW_TO_SCHEME_EMAIL } from '../routes';
import {
  clearValidationErrorsFor,
  firstValidationError,
  FormError,
  FormValidations,
  hasValidationErrors,
  validationErrorsFor,
} from '../../../common/formValidation';
import { nameIsEmpty, nameTooLong } from './nameValidation';
import { SignInPromptCard } from '../../../components/signInPromptCard/SignInPromptCard';
import styles from './namePage.module.scss';
import { Names } from '../CollectDetailsFlow';
import { Page } from '../../../components/page/Page';

const forenamesValidations: FormValidations = [
  { message: 'Please enter your first name', validate: nameIsEmpty },
  {
    message: 'Please enter a name that has less than 51 characters',
    validate: nameTooLong,
  },
];

const surnameValidations: FormValidations = [
  { message: 'Please enter your surname', validate: nameIsEmpty },
  {
    message: 'Please enter a name that has less than 51 characters',
    validate: nameTooLong,
  },
];

interface NamePageProps {
  readonly initialNames: Names | null;
  readonly onSuccessfulSubmit: (names: Names) => void;
}

export function NamePage({ initialNames, onSuccessfulSubmit }: NamePageProps): ReactElement {
  const history = useHistory();
  const [firstName, setFirstName] = useState(initialNames?.firstName || '');
  const [lastName, setLastName] = useState(initialNames?.lastName || '');
  const [validationErrors, setValidationErrors] = useState<ReadonlyArray<FormError>>([]);

  const handleSubmit = (evt: FormEvent): void => {
    evt.preventDefault();

    const validation = [
      { label: 'First name', message: firstValidationError(firstName, forenamesValidations) },
      { label: 'Surname', message: firstValidationError(lastName, surnameValidations) },
    ];

    if (hasValidationErrors(validation)) {
      setValidationErrors(validation);
    } else {
      onSuccessfulSubmit({ firstName, lastName });
      history.push(NEW_TO_SCHEME_EMAIL);
    }
  };

  return (
    <Page
      title='Create your Motability Scheme online account'
      validationErrors={validationErrors}
      beforeForm={beforeFormContent()}
    >
      <form onSubmit={handleSubmit}>
        <SimpleInput
          hintText='Please do not include middle names'
          labelText='First name'
          inputName='forenames'
          value={firstName}
          onChange={(evt: ChangeEvent<HTMLInputElement>): void => {
            setValidationErrors(clearValidationErrorsFor('First name'));
            setFirstName(evt.target.value);
          }}
          errors={validationErrorsFor('First name', validationErrors)}
          spellCheck={false}
          autoCorrect='off'
          data-cs-mask='data-cs-mask'
        />
        <div className={styles.surname}>
          <SimpleInput
            labelText='Surname'
            inputName='surname'
            value={lastName}
            onChange={(evt: ChangeEvent<HTMLInputElement>): void => {
              setValidationErrors(clearValidationErrorsFor('Surname'));
              setLastName(evt.target.value);
            }}
            errors={validationErrorsFor('Surname', validationErrors)}
            spellCheck={false}
            autoComplete='family-name'
            autoCorrect='off'
            data-cs-mask='data-cs-mask'
          />
        </div>
        <LoadableButton loading={false} notLoadingText='Continue' className={styles.continueButton} />
      </form>
      <SignInPromptCard />
    </Page>
  );
}

function beforeFormContent(): ReactElement {
  return (
    <div className={styles.beforeForm}>
      <p>To create your account we need your first name and surname.</p>
      <InfoNotificationBox variant='white-info'>
        If you manage someone else’s allowance please use your own name to create the online account
      </InfoNotificationBox>
    </div>
  );
}
