import React, { ReactElement } from 'react';
import { Card } from '@mo/layout-components';
import styles from './infoCard.module.scss';
import { externalLinkClicked } from '../../common/googleAnalytics/events';

interface InfoCardProps {
  infoHeader: string;
  infoMessage: string;
  icon: string;
  linkUrl: string;
}

export function InfoCard({ infoHeader, infoMessage, icon, linkUrl }: InfoCardProps): ReactElement {
  return (
    <a
      target={'_blank'}
      rel='noreferrer'
      href={linkUrl}
      className={styles.cardLink}
      onClick={(): void => externalLinkClicked(infoHeader)}
    >
      <Card className={styles.card}>
        <div>
          <img src={icon} alt='' className={styles.cardIcon} />
        </div>
        <div className={styles.infoBody}>
          <span className={styles.infoHeader}>{infoHeader}</span>
          <span>{infoMessage}</span>
        </div>
      </Card>
    </a>
  );
}
