import React, {ReactElement, ReactNode} from 'react';
import cn from 'classnames';
import CardShimmer from './CardShimmer';
import './Card.scss';

interface CardProps {
    children: ReactNode;
    className?: string;
    loading?: boolean;
    shimmerClassName?: string;
}

export default function Card({children, className, loading, shimmerClassName}: CardProps): ReactElement {
    if (loading) {
        return <CardShimmer className={shimmerClassName}/>;
    }
    return <div className={cn('card', className)}>{children}</div>;
}
