import React, { ChangeEvent, ReactElement, useState } from 'react';
import styles from './passwordInput.module.scss';
import classnames from 'classnames';
import ValidationList from './validation/ValidationList';

interface PasswordProps {
  password: string;
  error: string | undefined;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const PasswordInput: React.FunctionComponent<PasswordProps> = ({ password, onChange, error }): ReactElement => {
  const [visibility, setVisibility] = useState<boolean>(false);

  const setPasswordVisibility = (newVisibility: boolean): void => {
    setVisibility(newVisibility);
  };

  return (
    <>
      <label className={styles.inputLabel} htmlFor='password'>
        Password
      </label>
      <div className={styles.inputHints} id={'password-input-hint-text'}>
        <span className={styles.hint}>This needs to have at least:</span>
        <ValidationList hasErrors={!!error} password={password} />
      </div>
      <div className={classnames({ [styles.errorPasswordInputGroup]: error }, styles.passwordInputGroup)}>
        <ul className={styles.inlineErrors}>
          {error && (
            <li key={error} className={styles.inlineErrorMessage}>
              {error}
            </li>
          )}
        </ul>

        <div className={styles.passwordInputFieldWithToggle}>
          <input
            id='password'
            className={classnames({ [styles.errorInputField]: error }, styles.inputField)}
            aria-describedby={'password-input-hint-text password-validation-list'}
            type={visibility ? 'text' : 'password'}
            value={password}
            onChange={onChange}
          />
          <button
            type='button'
            className={classnames({ [styles.errorButton]: error }, styles.showHideButton)}
            onClick={(): void => setPasswordVisibility(!visibility)}
            data-ga={!visibility ? 'show-password' : 'hide-password'}
            aria-live={'polite'}
            aria-label={
              !visibility
                ? 'Show password - By clicking on this button, text typed in the password field will be displayed and will be spoken by screen readers'
                : "Hide password - By clicking on this button, text typed in the password field will be hidden and won't be spoken by screen readers"
            }
          >
            {visibility ? 'Hide password' : 'Show password'}
          </button>
        </div>
      </div>
    </>
  );
};

export default PasswordInput;
