import React, {Component} from 'react';
import PropTypes from 'prop-types';
import sadCarSrc from './sad-car.gif';
import {TechnicalDetails} from './TechnicalDetails';
import './unhandled-error.scss';

class UnhandledError extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      info: null
    };
  }

  componentDidCatch(error, info) {
    this.setState({
      hasError: true,
      error,
      info
    });
  }

  render() {
    if (this.state.hasError) {
      const errorMessage = this.state.error && this.state.error.stack || 'Unknown error';
      this.props.callback && this.props.callback();

      return (
        <div className='unhandled-error__content'>
          <h1 className='unhandled-error__title'>We’ve temporarily broken down</h1>
          <p>
            Please try refreshing the page. If this problem persists please contact Dealer Contact Services
            on {this.props.dealerContactServices}.
          </p>
          <img className='unhandled-error__sad-car' src={this.props.imageUrl} alt='sad car'/>
          <TechnicalDetails message={errorMessage} hidden={this.props.hideTechnicalDetails}/>
        </div>
      );
    }
    return this.props.children;
  }
}

UnhandledError.defaultProps = {
  dealerContactServices: '0300 037 0200',
  imageUrl: sadCarSrc
};

UnhandledError.propTypes = {
  dealerContactServices: PropTypes.node.isRequired,
  hideTechnicalDetails: PropTypes.bool,
  children: PropTypes.node,
  imageUrl: PropTypes.string.isRequired,
  callback: PropTypes.func
};

export default UnhandledError;
