import React, { ReactElement } from 'react';
import styles from './signInPromptCard.module.scss';

export function SignInPromptCard(): ReactElement {
  return (
    <div className={styles.signInPromptCard}>
      <h2 className={styles.signInPromptHeading}>Already have an account?</h2>
      <p>If you have already created your Motability Scheme online account, please sign in below.</p>
      <a className={styles.signInButton} href='https://myaccount.motabilityoperations.co.uk/'>
        Sign in to your account
      </a>
    </div>
  );
}
