export const NEW_TO_SCHEME_BASE_PATH = '/create';

export const NEW_TO_SCHEME_PRECHECK = `${NEW_TO_SCHEME_BASE_PATH}/pre-check`;
export const NEW_TO_SCHEME_NAME = `${NEW_TO_SCHEME_BASE_PATH}/name`;
export const NEW_TO_SCHEME_EMAIL = `${NEW_TO_SCHEME_BASE_PATH}/email`;
export const NEW_TO_SCHEME_EMAIL_SENT = `${NEW_TO_SCHEME_EMAIL}/sent`;

// This is the URL used in the email sent by activation service, update both places if changing this link
export const NEW_TO_SCHEME_VERIFY = `${NEW_TO_SCHEME_BASE_PATH}/v/:emailToken`;
export const NEW_TO_SCHEME_PASSWORD = `${NEW_TO_SCHEME_BASE_PATH}/password/:emailToken`;
export const NEW_TO_SCHEME_PASSWORD_NO_TOKEN = `${NEW_TO_SCHEME_BASE_PATH}/password`;
export const NEW_TO_SCHEME_EXPIRED = `${NEW_TO_SCHEME_BASE_PATH}/expired`;
