export class ApplicationError extends Error {
  public readonly message: string = 'Unexpected error occurred';
  public readonly name: string = 'ApplicationError';

  public constructor(name: string, message: string) {
    super();

    this.name = name;
    this.message = message;
  }

  public isSystemError(): boolean {
    return false;
  }

  public isServiceUnavailableError(): boolean {
    return false;
  }

  public isActivationError(): boolean {
    return false;
  }

  public isActivationTimeoutError(): boolean {
    return false;
  }

  public isAccountAlreadyExistsError(): boolean {
    return false;
  }

  public isPasswordValidationError(): boolean {
    return false;
  }

  public isDetailsMatchError(): boolean {
    return false;
  }

  public isCodeNotFoundError(): boolean {
    return false;
  }

  public isInvalidCrnError(): boolean {
    return false;
  }
}

export class SystemError extends ApplicationError {
  public constructor(statusCode?: number, message?: string) {
    super('SystemError', `Got a ${statusCode}: ${message}`);
    Object.setPrototypeOf(this, new.target.prototype);
  }

  public isSystemError(): boolean {
    return true;
  }
}

export class AccountAlreadyExistsError extends ApplicationError {
  public constructor() {
    super('AccountAlreadyExistsError', 'An account with this username already exists');
  }

  public isAccountAlreadyExistsError(): boolean {
    return true;
  }
}

export class InvalidTokenError extends ApplicationError {
  public constructor() {
    super('InvalidTokenError', 'The token used was invalid');
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export class InvalidPasswordError extends ApplicationError {
  public constructor() {
    super('InvalidPasswordError', 'The password used was invalid');
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export class PingPasswordViolationError extends ApplicationError {
  public readonly over64Characters: boolean;
  public readonly passwordIsUsername: boolean;
  public readonly foundInCommonlyUsedList: boolean;
  public readonly containsInvalidCharacters: boolean;

  public constructor(
    over64Characters: boolean,
    passwordIsUsername: boolean,
    foundInCommonlyUsedList: boolean,
    containsInvalidCharacters: boolean,
  ) {
    super('PingPasswordViolationError', 'The password used has not met ping requirements');
    Object.setPrototypeOf(this, new.target.prototype);

    this.over64Characters = over64Characters;
    this.passwordIsUsername = passwordIsUsername;
    this.foundInCommonlyUsedList = foundInCommonlyUsedList;
    this.containsInvalidCharacters = containsInvalidCharacters;
  }
}
