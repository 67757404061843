import React, {ReactElement} from 'react';
import './spinner-circle.scss';


export type SpinnerCircleProps = {
    theme: string;
}

export default function SpinnerCircle({ theme }: SpinnerCircleProps): ReactElement {
    return (
        <svg className={`loadable-button__spinner--${theme}`} viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
            <circle
                className="loadable-button__path"
                cx="25"
                cy="25"
                r="20"
                fill="none"
                strokeWidth="5"
                strokeLinecap="round"/>
        </svg>
    );
}
