import React, { ReactElement } from 'react';
import { CollectDetailsFlow } from './CollectDetailsFlow';
import { CreateAccountFlow } from './CreateAccountFlow';
import { Route } from 'react-router';
import { Redirect } from '../../components/redirect/Redirect';
import { NEW_TO_SCHEME_BASE_PATH, NEW_TO_SCHEME_PRECHECK } from './routes';

export function NewToSchemeFlow(): ReactElement {
  return (
    <>
      <Route exact path={NEW_TO_SCHEME_BASE_PATH}>
        <Redirect to={NEW_TO_SCHEME_PRECHECK} />
      </Route>
      <CollectDetailsFlow />
      <CreateAccountFlow />
    </>
  );
}
