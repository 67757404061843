import React, { ReactElement } from 'react';
import styles from './ShimmerLine.module.scss';
import cn from 'classnames';

export function ShimmerLine({
  height = '26px',
  width = '100%',
  className,
  ...shimmerProps
}: {
  height?: string;
  width?: string;
  className?: string;
}): ReactElement {
  return (
    <div data-locator='shimmer' {...shimmerProps} className={cn(styles.shimmer, className)} style={{ height, width }} />
  );
}
