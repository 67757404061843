import React, { ReactElement, useState } from 'react';
import { Route, Switch } from 'react-router';
import {
  NEW_TO_SCHEME_EXPIRED,
  NEW_TO_SCHEME_PASSWORD,
  NEW_TO_SCHEME_PASSWORD_NO_TOKEN,
  NEW_TO_SCHEME_VERIFY,
} from './routes';
import { PasswordPage } from './password/PasswordPage';
import { VerificationLinkExpiredPage } from './verificationLinkExpired/VerificationLinkExpiredPage';
import { VerifyPage } from './verify/VerifyPage';

export interface VerificationParams {
  readonly emailToken: string;
}

export function CreateAccountFlow(): ReactElement {
  const [verifiedToken, setVerifiedToken] = useState('');

  return (
    <Switch>
      <Route exact path={NEW_TO_SCHEME_VERIFY}>
        <VerifyPage setVerifiedToken={setVerifiedToken} />
      </Route>
      <Route exact path={NEW_TO_SCHEME_PASSWORD}>
        <PasswordPage verifiedToken={verifiedToken} />
      </Route>
      <Route exact path={NEW_TO_SCHEME_PASSWORD_NO_TOKEN} component={VerificationLinkExpiredPage} />
      <Route exact path={NEW_TO_SCHEME_EXPIRED} component={VerificationLinkExpiredPage} />
    </Switch>
  );
}
