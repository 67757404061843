import React, { ReactNode } from 'react';

import './infoBox.scss';
import {IconInfo} from '@mo/icons';

interface Props {
    title: string;
    children: ReactNode;
    color: string;
}
const InfoBox = (props: Props) => {
  return (
    <div className='infoBox' style={{color:  props.color, borderColor: props.color}}>
      <IconInfo color={props.color}  width={'100'} height={'100'} />
      <p className='infoBoxTitle'>{props.title}</p>
      <p className='infoBoxDetail'>{props.children}</p>
    </div>
  );
};

export default InfoBox;
