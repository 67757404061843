import React from 'react';
import PropTypes from 'prop-types';
import './force-focus.scss';

class ForceFocus extends React.Component {

  constructor(props) {
    super(props);
    this.focalPoint = React.createRef();
  }

  focus = (domNode) => {
    if (domNode) {
      domNode.focus();
    }
  };

  componentDidMount() {
    if (this.props.focus) {
      this.focus(this.focalPoint.current);
      this.props.removeFocus && this.props.removeFocus()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.focus && !prevProps.focus) {
      this.focus(this.focalPoint.current);
      this.props.removeFocus && this.props.removeFocus()
    }
  }

  render() {
    return (
      <div aria-live='assertive'
           tabIndex={-1}
           className='accessibility__force-focus'
           data-locator='force-focus'
           ref={this.focalPoint}>
        {this.props.children}
      </div>
    );
  }
}

ForceFocus.defaultProps = {
  focus: true
};

ForceFocus.propTypes = {
  children: PropTypes.node,
  focus: PropTypes.bool,
  removeFocus: PropTypes.func
};

export default ForceFocus;
