import React, {ReactElement} from 'react';

interface CardListProps {
    children?: ReactElement | ReactElement[];
    className?: string;
}

export default function CardList({children, className}: CardListProps): ReactElement {
    return <section className={className}>{children}</section>;
}
