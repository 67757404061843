import * as React from 'react';
import styles from './verificationLinkExpiredPage.module.scss';
import { NEW_TO_SCHEME_NAME } from '../routes';
import { Page } from '../../../components/page/Page';

export const VerificationLinkExpiredPage: React.FunctionComponent = (): React.ReactElement => {
  return (
    <Page title='This link has expired' withSecurityPromise={false}>
      <p className={styles.errorText}>
        Your secure link to complete your Motability Scheme online account setup expired after two hours or has already
        been used.
      </p>
      <p className={styles.errorText}>Use the button below if you would still like to create your account.</p>
      <a href={NEW_TO_SCHEME_NAME} className={styles.linkExpiredErrorPageButton}>
        Create your account
      </a>
    </Page>
  );
};
