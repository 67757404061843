import React, { ReactElement, ReactNode } from 'react';
import cn from 'classnames';
// @ts-ignore
import { ForceFocus } from '@mo/accessibility';
import './service-error-message.scss';
import WarningIcon from './WarningIcon';

interface ServiceErrorMessageProps {
    visible?: boolean;
    hintClassName?: string;
    titleClassName?: string;
    messageClassName?: string;
    className?: string;
    icon?: ReactNode;
    title: string;
    errorCode?: ReactNode;
    forceFocus?: boolean;
    children: ReactNode;

    [x: string]: unknown;
}

function ServiceErrorMessage(props: ServiceErrorMessageProps): ReactElement | null {
    const {
        visible,
        hintClassName,
        titleClassName,
        messageClassName,
        className,
        icon,
        title,
        children,
        errorCode,
        forceFocus = true,
        ...otherProps
    } = props;

    const containerStyles = cn('service-error-message__container', className);
    const hintStyles = cn('service-error-message__hint', hintClassName);
    const titleStyles = cn('service-error-message__title', titleClassName);
    const messageStyles = cn('service-error-message__message', messageClassName);

    function getMessageDiv() {
        return <div
            key={Date.now()}
            className={containerStyles}
            data-locator='service-error'
            data-ga='technical-error'
            {...otherProps}
        >
            <div className={hintStyles} data-locator='error-inline-hint'>
                {icon ? icon : <WarningIcon/>}
                <p className={titleStyles}>{title}</p>
            </div>
            {errorCode}
            <div className={messageStyles}>{children}</div>
        </div>;
    }

    function getServiceErrorMessage() {
        return (
            forceFocus ?
                <ForceFocus>
                    {getMessageDiv()}
                </ForceFocus>
                : getMessageDiv()
        );
    }

    return visible ? getServiceErrorMessage() : null;
}

export default ServiceErrorMessage;
