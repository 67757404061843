import React, { FormEvent, ReactElement, useState } from 'react';
import { createAccount } from './createAccountGateway';
import { InvalidTokenError, PingPasswordViolationError } from '../../../common/errors';
import { Redirect, useHistory } from 'react-router-dom';
import { NEW_TO_SCHEME_EXPIRED, NEW_TO_SCHEME_VERIFY } from '../routes';
import { SUCCESS_PAGE } from '../../../common/routes';
import { head, isEmpty } from 'lodash';
import { firstValidationError, FormError, FormValidations, validationErrorsFor } from '../../../common/formValidation';
import { Page } from '../../../components/page/Page';
import PasswordInput from '../../../components/passwordInput/PasswordInput';
import { TechnicalError } from '../../../components/technicalError/TechnicalError';
import { LoadableButton } from '@mo/loadable-button';
import { passwordContainsInvalidCharacters, passwordDoesNotMeetCriteria } from './passwordValidation';
import styles from './passwordPage.module.scss';
import { useParams } from 'react-router';
import { VerificationParams } from '../CreateAccountFlow';
import { transformPingPasswordErrorsToFormError } from './transformPingPasswordErrors';
import { ExternalLink } from '@mo/external-link';

interface PasswordPageProps {
  readonly verifiedToken: string;
}

export const GENERIC_PASSWORD_ERROR = 'Please enter a password that meets the requirements';

const passwordValidations: FormValidations = [
  { message: 'Please enter a password that meets the requirements', validate: passwordDoesNotMeetCriteria },
  { message: 'You have entered an invalid character', validate: passwordContainsInvalidCharacters },
];

export function PasswordPage({ verifiedToken }: PasswordPageProps): ReactElement {
  const history = useHistory();
  const [gatewayError, setGatewayError] = useState(false);
  const [password, setPassword] = useState('');
  const [submitLoading, setSubmitLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState<ReadonlyArray<FormError>>([]);
  const { emailToken: unverifiedToken } = useParams<VerificationParams>();

  async function onSubmit(formEvent: FormEvent): Promise<void> {
    formEvent.preventDefault();

    const validationError = firstValidationError(password, passwordValidations);
    setValidationErrors([{ label: 'Password', message: validationError }]);

    setGatewayError(false);

    if (isEmpty(validationError)) {
      try {
        setSubmitLoading(true);
        await createAccount(verifiedToken, password);
        history.push(SUCCESS_PAGE);
      } catch (err) {
        if (err instanceof InvalidTokenError) {
          history.push(NEW_TO_SCHEME_EXPIRED);
        } else if (err instanceof PingPasswordViolationError) {
          setSubmitLoading(false);
          setValidationErrors(transformPingPasswordErrorsToFormError(err));
        } else {
          setSubmitLoading(false);
          setGatewayError(true);
        }
      }
    }
  }

  if (isEmpty(verifiedToken)) {
    return <Redirect to={NEW_TO_SCHEME_VERIFY.replace(':emailToken', unverifiedToken)} />;
  }

  return (
    <Page title='Create a password' validationErrors={validationErrors}>
      <p>Please create a password for your new account</p>
      <form onSubmit={onSubmit} className={styles.form}>
        <PasswordInput
          password={password}
          error={
            validationErrors.length > 1
              ? GENERIC_PASSWORD_ERROR
              : head(validationErrorsFor('Password', validationErrors))
          }
          onChange={(event): void => {
            setPassword(event.target.value);
            setValidationErrors([]);
          }}
        />
        <TechnicalError visible={gatewayError} errorCode='SP1'>
          Sorry, we're having some problems creating your password. Please try again in a few moments. If this problem
          persists please call us on <a href={'tel:0300 456 4566'}>0300 456 4566</a> and quote the error code to the
          advisor.
        </TechnicalError>
        <LoadableButton
          loading={submitLoading}
          className={styles.continueButton}
          notLoadingText='Submit'
          loadingText='Submitting'
        />
      </form>
      <p>
        By creating an account, you agree to the Motability Operations{' '}
        <ExternalLink
          href='https://www.motability.co.uk/utilities/terms-and-conditions'
          analyticsTag='website-terms-and-conditions'
        >
          Terms and Conditions
        </ExternalLink>{' '}
        and{' '}
        <ExternalLink href='https://www.motability.co.uk/utilities/privacy-notice/' analyticsTag='privacy-notice'>
          Privacy Notice
        </ExternalLink>
        .
      </p>
    </Page>
  );
}
