import React, {
    ReactElement,
    ReactNode,
} from 'react';
import ServiceErrorMessage from './ServiceErrorMessage';

interface GenericServerErrorProps {
    visible?: boolean;
    dealerContactServices: ReactNode;
    className?: string;
    showDealerContactInfo?: boolean;
    correlationId?: string;
}

function GenericServerError({
    visible,
    dealerContactServices = '0300 037 0200',
    className,
    showDealerContactInfo = true,
    correlationId,
}: GenericServerErrorProps): ReactElement {

    return (
        <ServiceErrorMessage title='Our fault - a technical error happened'
                             visible={visible}
                             className={className}>
            {showDealerContactInfo ?
                <p>Please try again in a few moments. If this problem persists please contact Dealer Contact Services
                    on {dealerContactServices}
                    {correlationId && ` and provide the following error code: ${correlationId}`}.</p> :
                <p>Please try again in a few moments.</p>}
        </ServiceErrorMessage>
    );
}

export default GenericServerError;
