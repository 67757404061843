import { SIGN_UP_BASE_PATH } from '../../common/routes';
import axios from 'axios';

interface FetchAccountUiUrlResponse {
  readonly url: string;
}

export async function fetchAccountUiUrl(): Promise<string> {
  const response: FetchAccountUiUrlResponse = (await axios.get(`${SIGN_UP_BASE_PATH}/api/account-url`)).data;
  return response.url;
}
