import React, { ReactElement } from 'react';
import { ShimmerLine } from './ShimmerLine';

interface pageLoadingProps {
  className?: string;
}

export const PageLoading: React.FunctionComponent<pageLoadingProps> = ({ className }): ReactElement => {
  return (
    <>
      <div
        aria-label={`Content loading`}
        aria-busy='true'
        data-locator='page-loading'
        className={className}
        role='progressbar'
      >
        <ShimmerLine width='40%' />

        <ShimmerLine />
        <ShimmerLine />
        <ShimmerLine />
      </div>
    </>
  );
};
