import React, { ReactElement } from 'react';
import './error-code.scss';

interface ErrorCodeProps {
  readonly errorCode: string;
}

function ErrorCode({ errorCode }: ErrorCodeProps): ReactElement {
  return <div className='error_code__container'>{`Error code - ${errorCode}`}</div>;
}

export default ErrorCode;