import React, {ReactElement} from 'react';
import {Shimmer} from '@mo/shimmer';
import Card from './Card';
import './CardShimmer.scss';

export default function CardShimmer({className}: { className?: string }): ReactElement {
    return (
        <Card className={className}>
            <header className='card-shimmer__header'>
                <div className='card-shimmer__icon' role='icon'/>
                <Shimmer loading={true} className='card-shimmer__heading'>
                    <h2/>
                </Shimmer>
            </header>
            <Shimmer loading={true}>
                <h3 className='card-shimmer__list-item'/>
                <h3 className='card-shimmer__list-item'/>
                <h3 className='card-shimmer__list-item'/>
            </Shimmer>
        </Card>
    );
}
