import { apiUrl } from '../../../common/gateway';
import axios, { AxiosError } from 'axios';

import {
  InvalidPasswordError,
  InvalidTokenError,
  PingPasswordViolationError,
  SystemError,
} from '../../../common/errors';

export async function createAccount(emailVerificationToken: string, password: string): Promise<void> {
  try {
    await axios.post(apiUrl('create-account', 'v4'), { emailVerificationToken, password });
  } catch (error) {
    if (error instanceof AxiosError) {
      const errorStatusCode = error?.response?.status;
      const errorMessage = error?.response?.data?.message;

      if (errorStatusCode === 422) {
        switch (errorMessage) {
          case 'Invalid token':
            throw new InvalidTokenError();
          case 'Invalid password':
            throw new InvalidPasswordError();
        }
      }

      if (errorStatusCode === 400) {
        const errors = error?.response?.data?.errors;
        throw new PingPasswordViolationError(
          errors.over64Characters,
          errors.passwordIsUsername,
          errors.foundInCommonlyUsedList,
          errors.containsInvalidCharacters,
        );
      }
      throw new SystemError(errorStatusCode, errorMessage);
    } else {
      throw new SystemError(500, (error as Error).message);
    }
  }
}
