import React, { ReactElement, useEffect, useState } from 'react';
import Title from '../../components/title/Title';
import styles from './activationCompletePage.module.scss';
import background from './background.svg';
import redCar from './red-car.svg';
import blueCar from './blue-car.svg';
import { fetchAccountUiUrl } from './ActivationCompleteGateway';
import { isEmpty } from 'lodash';
import { PageLoading } from '../../components/pageLoading/PageLoading';

export const ActivationCompletePage = (): ReactElement => {
  const [accountUiUrl, setAccountUiUrl] = useState('');

  useEffect((): void => {
    async function fetchUrl(): Promise<void> {
      const url = await fetchAccountUiUrl();
      setAccountUiUrl(url);
    }
    fetchUrl();
  }, []);

  if (isEmpty(accountUiUrl)) {
    return <PageLoading />;
  } else {
    return (
      <div data-locator={'account-created'} className={styles.activationCompletePage}>
        {isEmpty(accountUiUrl) && <PageLoading />}
        {!isEmpty(accountUiUrl) && (
          <>
            <img className={styles.background} src={background} alt={''} />
            <img className={styles.blueCar} src={blueCar} alt={''} />
            <img className={styles.redCar} src={redCar} alt={''} />
            <div className={styles.content}>
              <Title className={styles.title} title={`Your Motability Scheme online account has been created`} />
              <p>Thanks for creating your online account. Sign in below to get started.</p>
              <a href={accountUiUrl} className={styles.button}>
                Sign in to your account
              </a>
            </div>
          </>
        )}
      </div>
    );
  }
};
